import {Component, TemplateRef} from '@angular/core'
import {BsModalRef} from 'ngx-bootstrap/modal'

import {DialogOptions} from '@core/schemes/ui/dialog'

@Component({
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {

  options: DialogOptions

  constructor(
    private bsModalRef: BsModalRef,
  ) {
  }

  hide(): void {
    this.bsModalRef.hide()
  }

  cancel(): void {
    this.options.onCancel?.()
    this.hide()
  }

  confirm(): void {
    this.options.onConfirm?.()
    this.hide()
  }

  get title(): string {
    return this.options.title
  }

  get message(): string {
    return this.options.message
  }

  get template(): TemplateRef<any> {
    return this.options.template
  }

  get cancelText(): string {
    return this.options.cancelText ?? 'Отмена'
  }

  get confirmText(): string {
    return this.options.confirmText ?? 'ОК'
  }
}

<ng-container *ngIf="options">

	<div class="modal-header" *ngIf="title">
		<div class="tw-title-3-semibold modal-title">{{ title }}</div>
		<button class="btn-close" (click)="cancel()"></button>
	</div>

	<div class="modal-body" *ngIf="message || template">
		<div *ngIf="message" [innerHTML]="message"></div>
		<ng-container *ngIf="template" [ngTemplateOutlet]="template"></ng-container>
	</div>

	<div class="modal-footer">
		<button class="btn btn-outline-secondary" (click)="cancel()">{{ cancelText }}</button>
		<button class="btn btn-primary {{options.confirmTextClass}}" (click)="confirm()">{{ confirmText }}</button>
	</div>

</ng-container>

